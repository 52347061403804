import { Progress, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getGS } from "../../APIs/functions";
import { useSearchParams } from "react-router-dom";
import './style.scss'
import { handleHotlinkClick, handleRadialClick } from "../../Utils/Gtag";

const Radial = () => {
  const [gsFeel, setGsFeel] = useState("");
  const [retryCount, setRetryCount] = useState(0);

  const queries = {
    retry: 1,
    refetchOnmount: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: Infinity
  };

  const [searchParams] = useSearchParams();
  const size = searchParams.get("radialSize");
  let upcCode = searchParams.get("upcCode");
  const label = searchParams.get("label")

  const {
    data: productData,
    isLoading: productDataLoading,
    isError: productDataError,
    refetch,
    isRefetchError,
    isRefetching
  } = useQuery(
    ["productGS", upcCode],
    () => getGS({ upcCode }),
    queries
  );

  const product = !productDataLoading && !productDataError ? productData[0] : "0"

  const getRadialColor = (value) => {
    let color = "";
    if (value >= 0 && value <= 25) {
      color = "#f15754";
    } else if (value >= 26 && value <= 49) {
      color = "#ef9434";
    } else if (value >= 50 && value <= 74) {
      color = "#edcd25";
    } else if (value >= 75 && value <= 89) {
      color = "#97d50e";
    } else if (value >= 90 && value <= 100) {
      color = "#3ec60e";
    }
    return color;
  };

  useEffect(() => {
    let score = Math.round(Number(product?.totalGs));
    if (score >= 90) {
      setGsFeel("Great 😍");
    } else if (score >= 75 && score <= 89) {
      setGsFeel("Good 😊");
    } else if (score >= 50 && score <= 74) {
      setGsFeel("Okay 🙂");
    } else if (score >= 26 && score <= 49) {
      setGsFeel("Limit 😐");
    } else if (score <= 24) {
      setGsFeel("Avoid 😶");
    } else {
      setGsFeel("");
    }
  }, [product]);

  useEffect(() => {
    if (!productDataLoading) {
      // Check UPC code validity and handle errors
      if (upcCode.length !== 11 && upcCode.length !== 12) {
        console.log(`GS Radial is not available for UPC: ${upcCode}`);
        window.parent.postMessage("Radial not available", "*");
        return;
      }

      // Check if product data is available
      if (productDataError || !product) {
        // Retry fetching data with modified UPC code
        if (retryCount === 0) {
          let modifiedUpcCode = upcCode;
          if (upcCode.length === 12 && upcCode.startsWith('0')) {
            modifiedUpcCode = upcCode.substring(1);
          } else if (upcCode.length !== 11 && upcCode.length !== 12) {
            console.log(`GS Radial is not available for UPC: ${upcCode}`);
            window.parent.postMessage("Radial not available", "*");
            return;
          } else {
            modifiedUpcCode = "0" + upcCode;
          }
          upcCode = modifiedUpcCode; // Update UPC code
          refetch().then(() => {
            setRetryCount(retryCount + 1);
          });
        } else {
          // Notify parent window about chart unavailability
          console.log(`GS Radial is not available for UPC: ${upcCode}`);
          window.parent.postMessage("Radial not available", "*");
        }
      }
    }
  }, [productDataLoading, productDataError, productData, upcCode, retryCount]);

  return (
    <div style={{ background: label === "true" ? "#F0F7F5" : "#fff", padding: label === "true" ? "10px 18px" : "0", borderRadius: label === "true" ? "10px" : "0", maxWidth: "max-content" }} className="d-flex align-items-center">
      {productDataLoading ?
        <div className="p-3"><Spin /></div>
        : (productDataError || !productData || productData.length === 0) ? null : <div className={`${size} d-flex flex-column flex-wrap gs-radial-wrap justify-content-center  align-items-center gap-1`}
          onClick={() => handleRadialClick(upcCode)}
        >
          {label === "true" && <span className="gs-text d-block text-center">
            GreenScore®
          </span>}
          <Progress
            type="circle"
            percent={Math.round(product?.totalGs)}
            className="fw-bold text-primary lh-base"
            strokeWidth="11"
            format={(percent) => percent + ""}
            strokeColor={`${getRadialColor(Math.round(product?.totalGs))}`}
          />
          {label === "true" && <span className="gs-feel-text d-block text-center pt-1">{gsFeel}
          </span>}

        </div>}
      {label === "true" && product?.totalGs && <a rel="noreferrer" onClick={handleHotlinkClick} className="gs-link-txt" target="_blank" href="https://about.greenchoicenow.com/greenscore"> What is GreenScore <sup className="copyright-icon">®</sup> ? </a>}
    </div>
  );
};

export default Radial;
