let domain = document.referrer.split('/')[2];

export const handleRadialClick = (upc) => {
    window.gtag("event", "gs_radial_click", {
        upc: upc,
        client_name: domain,
    });
};

export const handleProductCardClick = (productName, upc) => {
    window.gtag("event", "product_card_click", {
        product: productName,
        client_name: domain,
        upc: upc
    });
};

export const handleProcessingGSClicked = (productName, upc) => {
    window.gtag("event", "gs_chart_processing", {
        product: productName,
        client_name: domain,
        upc: upc
    });
};

export const handleNutritionGSClicked = (productName, upc) => {
    window.gtag("event", "gs_chart_nutrition", {
        product: productName,
        client_name: domain,
        upc: upc
    });
};

export const handleFoodSafetyGSClicked = (productName, upc) => {
    window.gtag("event", "gs_chart_food_safety", {
        product: productName,
        client_name: domain,
        upc: upc
    });
};

export const handleEnvironmentGSClicked = (productName, upc) => {
    window.gtag("event", "gs_chart_environment", {
        product: productName,
        client_name: domain,
        upc: upc
    });
};

export const handleDailyValueModal = (productName, upc) => {
    window.gtag("event", "gs_daily_value_modal", {
        product: productName,
        client_name: domain,
        upc: upc
    });
}

export const handleFoodSafetyModal = (productName, upc) => {
    window.gtag("event", "gs_food_safety_modal", {
        product: productName,
        client_name: domain,
        upc: upc
    });
}

export const handleProcessingModal = (productName, upc) => {
    window.gtag("event", "gs_processing_modal", {
        product: productName,
        client_name: domain,
        upc: upc
    });
}

export const handleEnvironmentModal = (productName, upc) => {
    window.gtag("event", "gs_environment_modal", {
        product: productName,
        client_name: domain,
        upc: upc
    });
}

export const handleHotlinkClick = () => {
    window.gtag("event", "greenscore_hotlink_click");
}