import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import ProductCard from '../ProductCard';
import 'swiper/css';
import 'swiper/css/navigation';
import './index.scss'

const ProductCarousel = ({ products, client, domain }) => {

  const productList = products

  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={15}
      slidesPerView={2.25}
      loop={false}
      navigation={products.length < 4 ? false : true}
      breakpoints={{
        767: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1020: {
          slidesPerView: 4,
          spaceBetween: 40,
        }
      }}
      watchOverflow={true}
    >
      {productList.length > 0 && productList.map(item => {
        return <SwiperSlide key={client ? item?.inv_pk : item?.productCode}>
          <ProductCard product={item} domain={domain} />
        </SwiperSlide>
      })}
    </Swiper>
  )
}

export default ProductCarousel